<template>
  <v-main>

    <v-container
        id="view-dependents-container"
        fluid
        tag="section"
    >

      <base-material-card
          icon="mdi-family-tree"
          class="px-5 py-3"
      >
        <v-card-text v-if="errors.length">
          <v-alert color="error"
                   outlined
                   dark
                   :key="error.id"
                   show variant="danger"
                   v-for="error in errors">
            {{ error }}
          </v-alert>
        </v-card-text>

          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-subtitle-2">Name</th>
                  <th class="text-subtitle-2">Relationship</th>
                  <th class="text-subtitle-2">Birth Year</th>
                  <th class="text-subtitle-2">Gender</th>
                  <th class="text-subtitle-2">Country</th>
                  <th class="text-subtitle-2">County</th>
                  <th class="text-subtitle-2">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="dependent in dependents"
                  :key="dependent.id"
                >
                  <td>
                    <router-link :to="{name: 'edit_dependent', params: {id: dependent.id}}">
                      {{ dependent.firstName }} {{ dependent.middleName }} {{ dependent.lastName }}
                    </router-link>
                  </td>
                  <td>{{ dependent.relationType.name }}</td>
                  <td>{{ dependent.birthYear }}</td>
                  <td>{{ dependent.gender }}</td>
                  <td>{{ dependent.country }}</td>
                  <td>{{ dependent.county }}</td>
                  <td>{{ dependentStatusMap[dependent.status] }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>


      </base-material-card>


    </v-container>

  </v-main>
</template>

<script>
import api from '@/services/api'
import geoUtils from '@/utils/geography'
import constants from '@/utils/constants'
import {mapGetters} from "vuex";

export default {
  name: 'CoreViewDependentsView',

  created() {
    this.$gtag.event('view_dependents')

    this.userId = this.getUserId
    this.fetchDependents()
  },

  computed: {
    ...mapGetters({
      getUserId: 'getUserId'
    }),
    computedStatus: {
      get() {
        return this.userStatusMap[this.user.status]
      },
      set(value) {
        this.user.status = this.userStatusMap.indexOf(value)
      }
    },
    computedCountry: {
      get() {
        return geoUtils.findCountryByCode(this.user.country)
      },
      set(value) {
        this.user.country = value
      }
    }
  },

  methods: {
    fetchDependents() {
      api
          .fetchDependents(this.userId)
          .then(response => {
            this.dependents = response.data
          })
          .catch(err => {
            this.handleError(err)
          })
    },
    handleError(err) {
      let errorMessage = api.parseResponseError(err)
      this.errors.push(errorMessage)
    }
  },

  data() {
    return {
      errors: [],
      userId: null,
      user: {
        country: 'US',
        idType: {}
      },
      dependents: [],
      userStatusMap: constants.getUserStatusMap(),
      dependentStatusMap: constants.getDependentStatusMap()
    }
  }
}
</script>
